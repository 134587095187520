
import { Component, Prop, Vue } from 'vue-property-decorator';

// Utilities
import { mdiHelpCircle } from '@mdi/js';

@Component
export default class RegisterTooltip extends Vue {
  @Prop({ default: true })
  isPostalCodeText!: boolean;

  icons = {
    helpCircle: mdiHelpCircle
  };
}
